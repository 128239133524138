<template>
  <div>
    <div class="AllCourse">
      <div class="container">
        <div class="AllCourse__top row mt-3 mb-5 align-items-center mx-auto">
          <form class="d-flex position-relative AllCourse__searchForm col-12 col-md-4" @submit.prevent="getData">
            <button class="btn AllCourse__searchBtn position-absolute" type="submit"><i class="fas fa-search"></i></button>
            <input class="form-control me-2 AllCourse__search" type="search" placeholder="輸入標籤 ex. #marketing" aria-label="Search" v-model="query">
          </form>
          <div v-if="submittedQuery" class="text-start fw-bold mt-3" style="font-size: 1.3rem;">
            {{ submittedQuery }}
          </div>
          <!-- <div class="AllCourse__top-badges d-flex align-items-center ms-auto justify-content-center col-12 col-md-6">
            <div class="AllCourse__badge" v-for="item in categories" :key="item.id"
              @click.prevent="CourseCategoryId = item.id;getData()">
              {{ item.name }}
            </div>

          </div> -->
          <!-- <div class="col-12 col-md-9 d-flex mt-3 mt-md-0 justify-content-end">
            <select class="form-select courses__filter me-2" aria-label="courses__filter" v-model="CourseCategoryId" @change="getData">
              <option value="1" selected='selected'>一般課程</option>
              <option :value="item.id" v-for="item in categories" :key="item.id">{{ item.name }}</option>
            </select>
            <select class="form-select courses__filter ms-2" aria-label="courses__filter" v-model="filter" @change="getData">
              <option value="latest" selected>最新發布</option>
              <option value="nop">最多人數</option>
              <option value="rating">最高評價</option>
            </select>
          </div> -->

        </div>
      </div>
      <div class="container">
        <div class="row" v-if="!noData">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" v-for="(item, i) in data" :key="`${i}${item.id}`">
            <CourseCard :course="item" />
          </div>
        </div>
        <div v-if="noData">
          <p class="noData__txt">找不到您搜尋的課程</p>
          <img src="../assets/images/empty.svg" alt="" width="250">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CourseCard from '../components/CourseCard';

export default {
  name: 'CoursesTag',
  components: {
    CourseCard,
  },
  data() {
    return {
      filter: 'latest',
      query: '',
      submittedQuery: '',
      data: [],
      page: 2,
      CourseName: 'SOLIDWORKS',
      CourseCategoryId: 0,
      OrderBy: 'lastest',
      Ordering: 'ASC',
      isUpdated: 0,
      routerQuery: '',
      noData: false,
      categories: [],
    };
  },
  methods: {
    toggleOrdering(order) {
      this.Ordering = order;
      this.data = [];
      this.getData();
    },
    getCategories() {
      this.$http.get(`${this.$API_PATH}/Course/Categories`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.categories = res.data.data.courseCategories;
        this.categories.splice(0, 1);
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    getData() {
      const query = {
        CourseName: this.query ? this.query : 'SOLIDWORKS',
        CourseCategoryId: this.CourseCategoryId,
        OrderBy: this.OrderBy,
        Ordering: this.Ordering,
        page: 1,
      };
      this.submittedQuery = this.query;
      this.updateLoading(true);
      this.$http.post(`${this.$API_PATH}/Course/All`, query, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        this.$log.debug(res.data);
        this.data = res.data.data.courseList;
        this.updateLoading(false);
        if (this.data.length == 0) {
          this.noData = true;
        } else {
          this.noData = false;
        }
      }).catch((err) => {
        this.updateLoading(false);
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    scrollLoad() {
      const vm = this;
      this.$log.debug(vm.page);
      const query = {
        CourseName: vm.CourseName,
        CourseCategoryId: vm.CourseCategoryId,
        OrderBy: vm.OrderBy,
        Ordering: vm.Ordering,
        page: vm.page,
      };
      window.addEventListener('scroll', function () {
        if (window.scrollY + window.innerHeight >= (document.body.offsetHeight - 100) && vm.isUpdated == 0) {
          vm.isUpdated = 1;
          if (vm.isUpdated == 1) {
            query.page = vm.page;
            vm.$http.post(`${this.$API_PATH}/Course/All`, query, {
              headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
              },
            }).then((res) => {
              this.$log.debug(res.data);
              vm.data.push(...res.data.data.courseList);
              if (res.data.data.courseList.length > 0) {
                vm.isUpdated = 0;
                vm.page++;
              } else {
                vm.isUpdated = 1;
              }
            }).catch((err) => {
              this.$router.push('/error');
              this.$log.debug(err.response);
            });
          }
        }
      });
    },
    ...mapActions(['updateLoading']),
  },
  mounted() {
    this.scrollLoad();
  },
  created() {
    this.getCategories();
    if (Object.values(this.$route.query)) {
      this.routerQuery = Object.values(this.$route.query).join('');
      this.query = this.routerQuery;
      const query = {
        CourseName: this.query,
        CourseCategoryId: this.CourseCategoryId,
        OrderBy: this.OrderBy,
        Ordering: this.Ordering,
        page: 1,
      };
      this.updateLoading(true);
      this.$http.post(`${this.$API_PATH}/Course/All`, query, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        this.$log.debug(res.data.data);
        this.data = res.data.data.courseList;
        this.updateLoading(false);
      }).catch((err) => {
        this.updateLoading(false);
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    } else {
      this.getData();
    }
    this.$log.debug(Object.values(this.$route.query).join(''));
  },
};
</script>
